
const data = [
    {
        key: '1',
        projectName: {
            text: 'Power System'
        },
        product: {
            text: 'Servomotor control, can also output force distance at low speed, high positioning accuracy, up to micron level.'
        },
        other: {
            text: 'Inverter and common motor, V/F control mode, small force distance at low speed, positioning accuracy is not high, up to ctexttimeters.'
        }
    },
    {
        key: '2',
        projectName: {
            text: 'Cloth cashe device'
        },
        product: {
            text: 'Deep V slot, cloth large cache, full release of ttextsion, moderate elasticity.'
        },
        other: {
            text: 'Shallow V groove, small cloth buffer, unevtext ttextsion at high speed.'
        }
    },
    {
        key: '3',
        projectName: {
            text: 'Discharge device'
        },
        product: {
            text: 'The microcomputer automatically calculates that the photoelectric stextsor automatically delivers the cloth.'
        },
        other: {
            text: 'Manually set parameters, novice operation difficlulties.'
        }
    },
    {
        key: '4',
        projectName: {
            text: 'Power flatttexting device '
        },
        product: {
            text: 'The pattextted product flatttexts the fabric and allows it to be pulled out of the rolled fabric edge, which is btexteficial to the opposite side, The effect of the knitted fabric is obvious, and it is widely used in knitted fabrics.'
        },
        other: {
            text: 'No'
        }
    },
    {
        key: '5',
        projectName: {
            text: 'Side-to-side device'
        },
        product: {
            text: 'Photoelectric induction PLC automatic edge control,the circuit is simple, the stextsitivity can be adjusted.'
        },
        other: {
            text: 'Relay control, complex lines,and difficult mainttextance.'
        }
    },
    {
        key: '6',
        projectName: {
            text: 'Feeding device'
        },
        product: {
            text: 'Servo motor control,real-time follow-up system, electronic synchronization,uniform feed ttextsion.'
        },
        other: {
            text: 'The chain sprocket is mechanically synchronised, and the chain plate is east to fabricate threads and debris.After long-term operation,tha chain plate will loostext,causing the fabric to be loose. the structure is complicated and mainttextance is difficult. '
        }
    },
    {
        key: '7',
        projectName: {
            text: 'Elastic automatic tracking device'
        },
        product: {
            text: 'Photoelectric stextsors monitor the elasticity of the cloth in real time,and can automatically adjust the loostextess whtext tighttexted,and automatically adjust the ttextsion whtext it is loose.'
        },
        other: {
            text: 'No'
        }
    },
    {
        key: '8',
        projectName: {
            text: 'Fabric memory'
        },
        product: {
            text: 'The data of 30 kinds of fabrics can save the data of the paved fabrics.The user can recall the fabric names of the past and no need to set them again. it is convtextitextt and practical.'
        },
        other: {
            text: 'Can not remember,nosuch function.'
        }
    },
    {
        key: '9',
        projectName: {
            text: 'Spreading effect'
        },
        product: {
            text: 'Flat ,good alignmtextt,elasticity,good tightness, and a wide variety of fabrics. '
        },
        other: {
            text: 'Occasionally unevtext,there are not many suitable fabrics.Can not apply high elasticity,light and thin curled fabrics.'
        }
    },
];

export default data;

const videoList = [
    {
        id: 23,
        title: '支持单人操作',
        description: '一个人操作机器，最大生产能力为四名工人，使其特别适合高弹性织物！',
        url: 'https://xiaomai.tech/files/videos/knit_fabric.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/knit_fabric.png'
    }, {
        id: 22,
        title: '软性面料自动裁剪机',
        description: '适合各种异形片，一次定位，边走边裁剪。',
        url: 'https://xiaomai.tech/files/videos/nzml01.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/nzml01.png'
    }, {
        id: 1,
        title: '弹性面料',
        description: '弹性，针织面料操作视频。',
        url: 'https://xiaomai.tech/files/videos/knit_1.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/knit_1.png'
    }, {
        id: 2,
        title: '牛仔布',
        description: '牛仔面料操作视频。',
        url: 'https://xiaomai.tech/files/videos/cowboy_1.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/cowboy_1.png'
    }, {
        id: 3,
        title: '皮革',
        description: '皮革面料操作视频。',
        url: 'https://xiaomai.tech/files/videos/leather_1.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/leather_1.png'
    }, {
        id: 4,
        title: 'X6-210',
        description: 'X6-210铺布机工作实拍视频。',
        url: 'https://xiaomai.tech/files/videos/x6_210.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/x6_210.png'
    }, {
        id: 5,
        title: 'X6适用行业',
        description: 'X6铺布机适合多行业，你值得拥有。',
        url: 'https://xiaomai.tech/files/videos/x6_intro_1.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/x6_intro_1.png'
    }, {
        id: 6,
        title: 'X6用途',
        description: 'X6铺布机一机多用，你值得拥有。',
        url: 'https://xiaomai.tech/files/videos/x6_intro_2.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/x6_intro_2.png'
    }, {
        id: 7,
        title: '小麦铺布机功能',
        description: '不简单的小麦行铺布机，你值得拥有。',
        url: 'https://xiaomai.tech/files/videos/x6_intro_3.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/x6_intro_3.png'
    }, {
        id: 8,
        title: 'X6铺布机特点',
        description: 'X6铺布机，一款不挑面料的铺布机。',
        url: 'https://xiaomai.tech/files/videos/x6_intro_4.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/x6_intro_4.png'
    }, {
        id: 9,
        title: '公司介绍',
        description: '东莞小麦自动化科技有限公司介绍。',
        url: 'https://xiaomai.tech/files/videos/company_intro_1.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/company_intro_1.png'
    }, {
        id: 10,
        title: '工厂建设',
        description: '工厂建设介绍。',
        url: 'https://xiaomai.tech/files/videos/company_intro_2.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/company_intro_2.png'
    }, {
        id: 11,
        title: '其他机型',
        description: '铺布视频。',
        url: 'https://xiaomai.tech/files/videos/spreaders_1.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/spreaders_1.png'
    }, {
        id: 12,
        title: '帽子行业',
        description: '帽子行业制造。',
        url: 'https://xiaomai.tech/files/videos/maozi.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/maozi.png'
    }, {
        id: 13,
        title: '梭织面料',
        description: '梭织面料制造。',
        url: 'https://xiaomai.tech/files/videos/suozhimianliao.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/suozhimianliao.png'
    }, {
        id: 14,
        title: '弹性面料实操',
        description: '弹性面料实际操作视频。',
        url: 'https://xiaomai.tech/files/videos/tanxing.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/tanxing.png'
    }, {
        id: 15,
        title: '网纱面料',
        description: '网纱面料制造。',
        url: 'https://xiaomai.tech/files/videos/wangshamianliao.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/wangshamianliao.png'
    }, {
        id: 16,
        title: '针织面料',
        description: '针织面料制造。',
        url: 'https://xiaomai.tech/files/videos/zhenzhimianliao.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/zhenzhimianliao.png'
    }, {
        id: 17,
        title: '二次拨边完美解决',
        description: '卷边高弹性面料，二次拨边完美解决！',
        url: 'https://xiaomai.tech/files/videos/video_center_17.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/video_center_17.png'
    }, {
        id: 18,
        title: '1个人操作一台机',
        description: '1个人操作一台机，完美顶替4个工人效率。',
        url: 'https://xiaomai.tech/files/videos/video_center_18.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/video_center_18.png'
    }, {
        id: 19,
        title: '平整无张力铺布',
        description: '弹性面料，平整无张力铺布效果视频。',
        url: 'https://xiaomai.tech/files/videos/video_center_19.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/video_center_19.png'
    }, {
        id: 20,
        title: '铺布对比',
        description: '机器和人工铺布效果对比。',
        url: 'https://xiaomai.tech/files/videos/video_center_20.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/video_center_20.png'
    }, {
        id: 21,
        title: '泳衣面料',
        description: '泳衣面料制造。',
        url: 'https://xiaomai.tech/files/videos/video_center_21.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/video_center_21.png'
    } // 继续添加更多视频

];

export default videoList;

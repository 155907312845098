import {useState, useEffect} from 'react';
import PageFooter from './components/pageFooter';
import {useTranslation} from 'react-i18next';
import {Route, Routes, useNavigate} from "react-router-dom";
import {Button, Menu, Row, Col, Tooltip} from 'antd';
import { UpOutlined } from '@ant-design/icons';
import './App.css';
import Home from './components/home';
import About from './components/about';
import Capacity from './components/capacity';
import Advantage from './components/advantage';
import Products from './components/products';
import Contact from './components/contact';
import X5Machine from "./components/products/x5";
import X6Machine from "./components/products/x6";
import ZMMachine from "./components/products/zm";
import SpreadersVideo from "./components/spreadersVideo";
import ChooseSpreadingMachine from "./components/purchaseGuide";
import {gtag_report_products_view, gtag_report_view_about, gtag_report_whatsapp_contact} from './utils';
import {I18nextProvider} from 'react-i18next';

const {SubMenu} = Menu;

function App() {
    const {t, i18n} = useTranslation();
    const [language, setLanguage] = useState(localStorage.getItem('appLanguage') || 'en');
    const [selectedKeys, setSelectedKeys] = useState(['home']);
    const [navBarClass, setNavBarClass] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        i18n.changeLanguage(language); // Set the i18n language on initial load
        document.cookie = "SIDCC=value; SameSite=None; Secure";
        document.cookie = "__Secure-1PSIDCC=value; SameSite=None; Secure";

        const handleScroll = () => {
            const currentSticky = window.pageYOffset > 150;
            if (currentSticky && navBarClass !== 'sticky') {
                setNavBarClass('sticky');
            } else if (!currentSticky && navBarClass !== '') {
                setNavBarClass('');
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [navBarClass, i18n, language]);  // 仅在navBarClass改变时重新订阅事件

    const toggleLanguage = () => {
        const newLang = i18n.language.startsWith('en') ? 'zh' : 'en';
        i18n.changeLanguage(newLang);
        setLanguage(newLang);
        localStorage.setItem('appLanguage', newLang); // Store the language in localStorage
        window.location.reload(); // Reload the page
    };


    const onMenuClick = (e) => {
        setSelectedKeys([e.key]);
        const path = e.key === 'home' ? '/' : `/${e.key}`; // 根据 key 值判断跳转路径
        if (['x5', 'x6', 'zm'].includes(e.key)) {
            // 如果点击的是x5, x6, 或 zm菜单，触发Google conversion tracking
            gtag_report_products_view(`/${e.key}`);
        } else if (['about']) {
            gtag_report_view_about(`/${e.key}`)
        }
        navigate(`${path}`);  // 使用 navigate 函数进行路由跳转
    }

    const handleWhatsAppClick = () => {
        gtag_report_whatsapp_contact();
    };

    const backToHome = () => {
        setSelectedKeys(['home']);
        navigate('/');
    }
    // 滚动到顶部函数
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <I18nextProvider i18n={i18n}>
            <div className="App">
                <Button
                    className="scroll-to-top"
                    icon={<UpOutlined />}
                    onClick={scrollToTop}
                    shape="circle"
                    size="large"
                />
                <div className="App-header">
                    <div className="partOne">
                        <div className='content1'>
                            <span>{t('welcome')}</span>
                            <Tooltip title={t('language_tooltip')}>
                                <img
                                    className="language-switch-btn"
                                    src="https://xiaomai.tech/files/images/i18n-50.png"
                                    alt={t('language_tooltip')}
                                    onClick={toggleLanguage}
                                />
                            </Tooltip>
                            <Button className="back-home-btn" type="link" onClick={backToHome}>
                                {t('home')}
                            </Button>
                        </div>
                    </div>

                    <div className="partTwo">
                        <div className='content2'>
                            <Row justify="space-around" align="middle">
                                <Col span={8}>
                                    <img src="https://xiaomai.tech/files/images/logo_top.png" className="logo1"
                                         alt="logo"/>
                                </Col>
                                <Col span={8}>
                                    <img src="https://xiaomai.tech/files/images/logoName.png" className="logo2"
                                         alt="name"/>
                                </Col>
                                <Col span={8}>
                                    <div className="whatsapp-info">
                                        <div className="whatsapp-button">
                                            <a href="https://wa.me/8618929131303?text="
                                               target="_blank"
                                               aria-label="Chat on WhatsApp"
                                               rel="noopener noreferrer"
                                               onClick={handleWhatsAppClick}
                                            >
                                                <img
                                                    src="https://xiaomai.tech/files/images/WhatsAppButtonGreenLarge.png"
                                                    alt="Chat on WhatsApp"/>
                                            </a>
                                        </div>
                                        <div className="contact-info">
                                            <div className="contact-row">
                                                <a href="mailto:gnaturepowdef@gmail.com"
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                >
                                                    <img src="https://xiaomai.tech/files/images/gmail-48.png"
                                                         alt="Gmail Icon"
                                                         className="icon"/>
                                                </a>
                                                <div className="contact-text">
                                                    <span className="contact-text-l">{t('email')}</span>
                                                    <span className="contact-text-r">gnaturepowdef@gmail.com</span>
                                                </div>
                                            </div>
                                            <div className="contact-row">
                                                <a href="https://wa.me/8618929131303?text=I%20am%20very%20interested%20in%20the%20fabric%20laying%20machine%20you%20are%20selling!"
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                >
                                                    <img src="https://xiaomai.tech/files/images/whatsapp-96.png"
                                                         alt="WhatsApp Icon" className="icon"/>
                                                </a>
                                                <div className="contact-text">
                                                    <span className="contact-text-l">{t('whatsapp')}</span>
                                                    <span className="contact-text-r">+8618929131303</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className={`partThree ${navBarClass}`}>
                        <div className="navbar">
                            <Menu mode="horizontal" theme="dark" selectedKeys={selectedKeys} onClick={onMenuClick}>
                                <Menu.Item key="home">
                                    <div className="menu-item">
                                        <span className="menu-item-cn"> {t('home')}</span>
                                    </div>
                                </Menu.Item>
                                <SubMenu title={<div className="menu-item">
                                    <span className="menu-item-cn"> {t('aboutUs')}</span>
                                </div>}>
                                    <Menu.Item key="about">
                                        <div className="menu-item">
                                            <span className="menu-item-cn">{t('companyProfile')}</span>
                                        </div>
                                    </Menu.Item>
                                    <Menu.Item key="capacity">
                                        <div className="menu-item">
                                            <span className="menu-item-cn">{t('productionCapacity')}</span>
                                        </div>
                                    </Menu.Item>
                                    <Menu.Item key="advantage">
                                        <div className="menu-item">
                                            <span className="menu-item-cn">{t('advantage')}</span>
                                        </div>
                                    </Menu.Item>
                                </SubMenu>
                                <SubMenu key="products" title={<div className="menu-item">
                                    <span className="menu-item-cn">{t('products')}</span>
                                </div>}>
                                    <Menu.Item key="x5">
                                        <div className="menu-item">
                                            <span className="menu-item-cn">{t('x5Series')}</span>
                                        </div>
                                    </Menu.Item>
                                    <Menu.Item key="x6">
                                        <div className="menu-item">
                                            <span className="menu-item-cn">{t('x6Series')}</span>
                                        </div>
                                    </Menu.Item>
                                    <Menu.Item key="zm">
                                        <div className="menu-item">
                                            <span className="menu-item-cn">{t('compositeMachine')}</span>
                                        </div>
                                    </Menu.Item>
                                </SubMenu>
                                <Menu.Item key="spreaders_video">
                                    <div className="menu-item">
                                        <span className="menu-item-cn">{t('videoHighlights')}</span>
                                    </div>
                                </Menu.Item>
                                <Menu.Item key="purchase_guide">
                                    <div className="menu-item">
                                        <span className="menu-item-cn">{t('purchaseGuide')}</span>
                                    </div>
                                </Menu.Item>
                                <Menu.Item key="contact">
                                    <div className="menu-item">
                                        <span className="menu-item-cn">{t('contactUs')}</span>
                                    </div>
                                </Menu.Item>
                            </Menu>
                        </div>
                    </div>
                </div>
                <div className="App-body">
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/about" element={<About/>}/>
                        <Route path="/capacity" element={<Capacity/>}/>
                        <Route path="/advantage" element={<Advantage/>}/>
                        <Route path="/products" element={<Products/>}/>
                        <Route path="/contact" element={<Contact/>}/>
                        <Route path="/x5" element={<X5Machine/>}/>
                        <Route path="/x6" element={<X6Machine/>}/>
                        <Route path="/zm" element={<ZMMachine/>}/>
                        <Route path="/purchase_guide" element={<ChooseSpreadingMachine/>}/>
                        <Route path="/spreaders_video" element={<SpreadersVideo/>}/>
                    </Routes>
                </div>
                <div className="App-footer">
                    <PageFooter/>
                </div>
            </div>
        </I18nextProvider>
    );
}

export default App;

// data.js

const newsItems = [
    {
        title: "Revolutionary New Spreading Machine Technology",
        content: "OSHIMA's new spreading machine can replace six traditional models, enhancing production efficiency.",
        image: "https://xiaomai.tech/files/images/newsItem_1.jpg",
        link: "https://baike.baidu.com/item/%E5%8D%95%E9%92%88%E7%BB%97%E7%BC%9D%E6%9C%BA/4111900?fr=ge_ala"
    },
    {
        title: "Significant Advancements in Automatic Spreading Machines",
        content: "The fully automatic spreading machine can unfold fabric rolls weighing up to 200 kilograms without tension.",
        image: "https://xiaomai.tech/files/images/newsItem_2.jpg",
        link: "https://baike.baidu.com/item/%E5%8D%95%E9%92%88%E7%BB%97%E7%BC%9D%E6%9C%BA/4111900?fr=ge_ala"
    },
    {
        title: "Global Launch of the First Electric Spreading Machine",
        content: "KWS Company invests in the world's first emission-free electric spreading machine, starting a new chapter in road construction.",
        image: "https://xiaomai.tech/files/images/newsItem_3.jpg",
        link: "https://baike.baidu.com/item/%E5%8D%95%E9%92%88%E7%BB%97%E7%BC%9D%E6%9C%BA/4111900?fr=ge_ala"
    },
    {
        title: "Environmental Innovations in the Spreading Machine Industry",
        content: "The new generation of spreading machines is equipped with the latest electronics and custom software to ensure optimal performance at all times.",
        image: "https://xiaomai.tech/files/images/newsItem_4.jpg",
        link: "https://baike.baidu.com/item/%E5%8D%95%E9%92%88%E7%BB%97%E7%BC%9D%E6%9C%BA/4111900?fr=ge_ala"
    },
    {
        title: "Expansion of Automatic Spreading Technology Applications",
        content: "Automatic spreading machines are now widely used not only for knit materials but also for heavy and other types of fabrics.",
        image: "https://xiaomai.tech/files/images/newsItem_5.jpg",
        link: "https://baike.baidu.com/item/%E5%8D%95%E9%92%88%E7%BB%97%E7%BC%9D%E6%9C%BA/4111900?fr=ge_ala"
    },
    {
        title: "Automation and Simplification of Spreading Machine Operations",
        content: "Modern spreading machines greatly simplify the fabric handling process from the cradle to the receiver through automatic threading systems.",
        image: "https://xiaomai.tech/files/images/newsItem_6.jpg",
        link: "https://baike.baidu.com/item/%E5%8D%95%E9%92%88%E7%BB%97%E7%BC%9D%E6%9C%BA/4111900?fr=ge_ala"
    },
    {
        title: "Spreading Machines with Fabric Memory Function",
        content: "The latest spreading machines can save data for 30 types of fabrics, simplifying the operation process and enhancing work efficiency.",
        image: "https://xiaomai.tech/files/images/newsItem_7.jpg",
        link: "https://baike.baidu.com/item/%E5%8D%95%E9%92%88%E7%BB%97%E7%BC%9D%E6%9C%BA/4111900?fr=ge_ala"
    },
    {
        title: "Market Response to Automatic Spreading Machines",
        content: "Due to their ease of operation and high efficiency, automatic spreading machines have been widely welcomed in the market.",
        image: "https://xiaomai.tech/files/images/newsItem_8.jpg",
        link: "https://baike.baidu.com/item/%E5%8D%95%E9%92%88%E7%BB%97%E7%BC%9D%E6%9C%BA/4111900?fr=ge_ala"
    },
    {
        title: "New Spreading Machines Boosting Textile Industry Development",
        content: "The design of new spreading machines has brought new development opportunities to the textile industry, especially in improving production efficiency.",
        image: "https://xiaomai.tech/files/images/newsItem_9.jpg",
        link: "https://baike.baidu.com/item/%E5%8D%95%E9%92%88%E7%BB%97%E7%BC%9D%E6%9C%BA/4111900?fr=ge_ala"
    },
    {
        title: "Next-Generation Intelligent Spreading Solutions",
        content: "The latest generation of spreading machines integrates advanced intelligent control systems, capable of precisely handling various types of fabrics.",
        image: "https://xiaomai.tech/files/images/newsItem_10.jpg",
        link: "https://baike.baidu.com/item/%E5%8D%95%E9%92%88%E7%BB%97%E7%BC%9D%E6%9C%BA/4111900?fr=ge_ala"
    }
];

export default newsItems;

import React from 'react';
import {Layout, Typography, Row, Col} from 'antd';
import './index.css';
import {useTranslation} from 'react-i18next';

const {Content} = Layout;
const {Title, Paragraph} = Typography;

export default function Contact() {
    const {t} = useTranslation();

    return (<Layout className="contactContainer">
        <Content>
            <Row className="imageRow">
                <img className="cityImage" src="https://xiaomai.tech/files/images/companybg.jpg" alt="City View"/>
            </Row>
            <Row className="infoRow">
                <Col span={24} offset={0}>
                    <div className="contactInfo">
                        <Title level={4}><strong>{t('jiangxi_factory_address')}:</strong></Title>
                        <Paragraph>
                            {t('jiangxi_factory_address_detail')}
                        </Paragraph>
                        <Title level={4}><strong>{t('dongguan_factory_address')}:</strong></Title>
                        <Paragraph>
                            {t('dongguan_factory_address_detail')}
                        </Paragraph>
                        <Title level={4}><strong>{t('phone')}:</strong></Title>
                        <Paragraph>400-050-1183</Paragraph>
                        <Title level={4}><strong>{t('email')}:</strong></Title>
                        <Paragraph>gnaturepowdef@gmail.com</Paragraph>
                        <Title level={4}><strong>{t('companyAddress')}:</strong></Title>
                        <Paragraph>xiaomai.tech</Paragraph>
                    </div>
                </Col>
            </Row>
        </Content>
    </Layout>);
}

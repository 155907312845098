import {Divider} from 'antd';
import HonorPage from "./honorPage";
import './index.css';
import {useTranslation} from 'react-i18next';

export default function About() {
    const {t} = useTranslation();
    return (
        <div className="about">
            <div className="aboutHeader" style={{
                backgroundImage: `url('https://xiaomai.tech/files/images/companydoor.jpg')`,
                backgroundSize: 'cover',
                height: '400px'
            }}>
            </div>
            <div>
                <Divider orientation="left" className="intro-eng">{t('companyIntro')}</Divider>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('companyDesc')}
                </p>
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('companyDescContent')}
                </p>
            </div>
            <div>
                <Divider orientation="left" className="intro-eng">{t('entSpirit')}</Divider>
                <div className="spirit">
                    <div className='spirit-content'>
                        <p>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('entSpiritDesc')}
                        </p>
                    </div>
                    <div className="spirit-process">
                        <div className="process">
                            <div className="circle">
                                <div>2012</div>
                                <div>{t('company_history_1')}</div>
                            </div>
                            <div>{t('company_history_2')}</div>
                        </div>
                        <div className="process">
                            <div className="circle">
                                <div>2016</div>
                                <div>{t('company_history_3')}</div>
                            </div>
                            <div>
                                <div>{t('company_history_4')}</div>
                                <div>{t('company_history_5')}</div>
                            </div>
                        </div>
                        <div className="process">
                            <div className="circle">
                                <div>2019</div>
                                <div>{t('company_history_6')}</div>
                            </div>
                            <div>
                                <div>{t('company_history_7')}</div>
                                <div>{t('company_history_8')}</div>
                                <div>“{t('company_history_9')}”</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Divider orientation="left" className="intro-eng">{t('ent_culture_1')}</Divider>
                <div className="culture">
                    <div className="culture-item">
                        <div className="title">{t('ent_culture_2')}</div>
                        <p>
                            {t('ent_culture_3')}
                        </p>
                    </div>
                    <div className="culture-item">
                        <div className="title">{t('ent_culture_4')}</div>
                        <p>
                            {t('ent_culture_5')}
                        </p>
                    </div>
                    <div className="culture-item">
                        <div className="title">{t('ent_culture_6')}</div>
                        <p>
                            {t('ent_culture_7')}
                        </p>
                    </div>
                    <div className="culture-item">
                        <div className="title">{t('ent_culture_8')}</div>
                        <p>
                            {t('ent_culture_9')}
                        </p>
                    </div>
                </div>
            </div>
            <div className="honor-container">
                <Divider orientation="left" className="intro-eng">{t('companyHonor')}</Divider>
                <HonorPage/>
            </div>
        </div>
    );
}

import React from 'react';
import {Table} from 'antd';
import './index.css';

const columns = [
    {
        title: () => (
            <div className="header-row">
                <span>机器型号</span>
                <span className="eng-text left-space">Model</span>
            </div>
        ),
        className: 'model-column',
        dataIndex: 'model',
        width:"300px",
        key: 'model',
        render: text => (
            <div>
                <div>{text.cn}</div>
                <div className="eng-text">{text.en}</div>
            </div>
        )
    },
    {
        title: () => <div className="header-row">X5-160</div>,
        className: 'x5-160-190-column',
        dataIndex: 'x5_160',
        key: 'x5_160',
        render: text => (
            <div>
                <div>{text.cn}</div>
                <div className="eng-text">{text.en}</div>
            </div>
        )
    },
    {
        title: () => <div className="header-row">X5-190</div>,
        className: 'x5-160-190-column',
        dataIndex: 'x5_190',
        key: 'x5_190',
        render: text => (
            <div>
                <div>{text.cn}</div>
                <div className="eng-text">{text.en}</div>
            </div>
        )
    },
    {
        title: () => <div className="header-row">X5-210</div>,
        className: 'x5-210-column',
        dataIndex: 'x5_210',
        key: 'x5_210',
        render: text => (
            <div>
                <div>{text.cn}</div>
                <div className="eng-text">{text.en}</div>
            </div>
        )
    },
    {
        title: () => <div className="header-row">X5-260</div>,
        className: 'x5-260-310-column',
        dataIndex: 'x5_260',
        key: 'x5_260',
        render: text => (
            <div>
                <div>{text.cn}</div>
                <div className="eng-text">{text.en}</div>
            </div>
        )
    },
    {
        title: () => <div className="header-row">X5-310</div>,
        className: 'x5-260-310-column',
        dataIndex: 'x5_310',
        key: 'x5_310',
        render: text => (
            <div>
                <div>{text.cn}</div>
                <div className="eng-text">{text.en}</div>
            </div>
        )
    }
];
const data = [
    {
        key: '1',
        model: {
            cn: '铺布宽度',
            en: 'Spread width',
        },
        x5_160: {
            cn: '1600mm',
            en: '',
        },
        x5_190: {
            cn: '1900mm',
            en: '',
        },
        x5_210: {
            cn: '2100mm',
            en: '',
        },
        x5_260: {
            cn: '2600mm',
            en: '',
        },
        x5_310: {
            cn: '3100mm',
            en: '',
        }
    },
    {
        key: '2',
        model: {
            cn: '最大面料重量',
            en: 'Maximum fabric weight',
        },
        x5_160: {
            cn: '80kg',
            en: '',
        },
        x5_190: {
            cn: '80kg',
            en: '',
        },
        x5_210: {
            cn: '80kg',
            en: '',
        },
        x5_260: {
            cn: '110kg',
            en: '',
        },
        x5_310: {
            cn: '110kg',
            en: '',
        }
    },
    {
        key: '3',
        model: {
            cn: '最大卷布直径',
            en: 'Maximum roll diameter',
        },
        x5_160: {
            cn: '450mm',
            en: '',
        },
        x5_190: {
            cn: '450mm',
            en: '',
        },
        x5_210: {
            cn: '450mm',
            en: '',
        },
        x5_260: {
            cn: '450mm',
            en: '',
        },
        x5_310: {
            cn: '450mm',
            en: '',
        }
    },
    {
        key: '4',
        model: {
            cn: '最大驱动速度',
            en: 'Maximum driving speed',
        },
        x5_160: {
            cn: '88m/min',
            en: '',
        },
        x5_190: {
            cn: '88m/min',
            en: '',
        },
        x5_210: {
            cn: '88m/min',
            en: '',
        },
        x5_260: {
            cn: '85m/min',
            en: '',
        },
        x5_310: {
            cn: '85m/min',
            en: '',
        }
    },
    {
        key: '5',
        model: {
            cn: '单拉最大高度',
            en: 'Single pull maximum height',
        },
        x5_160: {
            cn: '220mm',
            en: '',
        },
        x5_190: {
            cn: '220mm',
            en: '',
        },
        x5_210: {
            cn: '220mm',
            en: '',
        },
        x5_260: {
            cn: '220mm',
            en: '',
        },
        x5_310: {
            cn: '220mm',
            en: '',
        }
    },
    {
        key: '6',
        model: {
            cn: '双拉最大高度',
            en: 'Double pull maximum height',
        },
        x5_160: {
            cn: '160mm',
            en: '',
        },
        x5_190: {
            cn: '160mm',
            en: '',
        },
        x5_210: {
            cn: '160mm',
            en: '',
        },
        x5_260: {
            cn: '160mm',
            en: '',
        },
        x5_310: {
            cn: '160mm',
            en: '',
        }
    },
    {
        key: '7',
        model: {
            cn: '功率',
            en: 'Power',
        },
        x5_160: {
            cn: '1.2KW',
            en: '',
        },
        x5_190: {
            cn: '1.2KW',
            en: '',
        },
        x5_210: {
            cn: '1.2KW',
            en: '',
        },
        x5_260: {
            cn: '1.55KW',
            en: '',
        },
        x5_310: {
            cn: '1.55KW',
            en: '',
        }
    },
    {
        key: '8',
        model: {
            cn: '电源要求',
            en: 'Power requirements',
        },
        x5_160: {
            cn: '单相交流220V 50Hz 15A',
            en: 'Single phase AC 220V 50Hz 15A',
        },
        x5_190: {
            cn: '单相交流220V 50Hz 15A',
            en: 'Single phase AC 220V 50Hz 15A',
        },
        x5_210: {
            cn: '单相交流220V 50Hz 15A',
            en: 'Single phase AC 220V 50Hz 15A',
        },
        x5_260: {
            cn: '单相交流220V 50Hz 15A',
            en: 'Single phase AC 220V 50Hz 15A',
        },
        x5_310: {
            cn: '单相交流220V 50Hz 15A',
            en: 'Single phase AC 220V 50Hz 15A',
        }
    },
    {
        key: '9',
        model: {
            cn: '尺寸:长宽高',
            en: 'Size:length,width,height',
        },
        x5_160: {
            cn: '230×165×95cm',
            en: '',
        },
        x5_190: {
            cn: '260×165×95cm',
            en: '',
        },
        x5_210: {
            cn: '280×165×95cm',
            en: '',
        },
        x5_260: {
            cn: '330×165×95cm',
            en: '',
        },
        x5_310: {
            cn: '380×165×95cm',
            en: '',
        }
    },
    {
        key: '10',
        model: {
            cn: '机器重量',
            en: 'Machine weight',
        },
        x5_160: {
            cn: '400kg',
            en: '',
        },
        x5_190: {
            cn: '415kg',
            en: '',
        },
        x5_210: {
            cn: '425kg',
            en: '',
        },
        x5_260: {
            cn: '475kg',
            en: '',
        },
        x5_310: {
            cn: '510kg',
            en: '',
        }
    },
    {
        key: '11',
        model: {
            cn: '裁板宽度',
            en: 'Panel width',
        },
        x5_160: {
            cn: '1835mm',
            en: '',
        },
        x5_190: {
            cn: '2135mm',
            en: '',
        },
        x5_210: {
            cn: '2335mm',
            en: '',
        },
        x5_260: {
            cn: '2835mm',
            en: '',
        },
        x5_310: {
            cn: '3335mm',
            en: '',
        }
    },
];

function CustomTable() {
    return (
        <Table
            columns={columns}
            dataSource={data}
            bordered
            size="middle"
            pagination={false}
            rowHoverable={false}
            height={700}
        />
    );
}

export default CustomTable;

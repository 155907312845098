import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.css"

function PartnersCarousel() {
    const settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 3000,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
    };

    return (
        <Slider {...settings} className="slide-header">
            <div className="slide-image"><img src="https://xiaomai.tech/files/images/partner_1.jpg" alt="Logo 1"  className="partner-img"/></div>
            <div className="slide-image"><img src="https://xiaomai.tech/files/images/partner_2.jpg" alt="Logo 2"  className="partner-img"/></div>
            <div className="slide-image"><img src="https://xiaomai.tech/files/images/partner_3.jpg" alt="Logo 3"  className="partner-img"/></div>
            <div className="slide-image"><img src="https://xiaomai.tech/files/images/partner_4.jpg" alt="Logo 4"  className="partner-img"/></div>
            <div className="slide-image"><img src="https://xiaomai.tech/files/images/partner_5.jpg" alt="Logo 5"  className="partner-img"/></div>
            <div className="slide-image"><img src="https://xiaomai.tech/files/images/partner_6.jpg" alt="Logo 6"  className="partner-img"/></div>
        </Slider>
    );
}

export default PartnersCarousel;

const data = [
    {
        key: '1',
        projectName: {
            text: '动力系统',
        },
        product: {
            text: '伺服电机控制，低速时也能输出大力矩，定位精度高，可达微米级。',
        },
        other: {
            text: '变频器与普通电机，V/F控制模式，低速时力距小，定位精度不高，可达厘米级。',
        }
    },
    {
        key: '2',
        projectName: {
            text: '布缓存装置',
        },
        product: {
            text: '深V槽，布缓存量大，张力充分释放，松紧适度。',
        },
        other: {
            text: '浅V槽，存缓存量小，高速时松紧度不均',
        }
    },
    {
        key: '3',
        projectName: {
            text: '解布装置',
        },
        product: {
            text: '微电脑自动计算，光电传感自动解布送布。',
        },
        other: {
            text: '人工设置参数，新手操作困难。',
        }
    },
    {
        key: '4',
        projectName: {
            text: '动力展平装置',
        },
        product: {
            text: '专利产品，将面料展平并可以将往里卷的布边 翻出，有利于对边，对于针织卷边面料效果显 著，广泛应用于针织面料。',
        },
        other: {
            text: '无',
        }
    },
    {
        key: '5',
        projectName: {
            text: '对边装置',
        },
        product: {
            text: '光电感应PLC自动对边控制，线路简单，灵敏度可调。',
        },
        other: {
            text: '继电器控制，线路复杂，维护困难。',
        }
    },
    {
        key: '6',
        projectName: {
            text: '送布装置',
        },
        product: {
            text: '伺服电机控制，实时跟随系统，电子同步，送布张 力均匀。',
        },
        other: {
            text: '链条链轮机械同步，链盘容易卡面料线头和杂物， 长期运行后链盘会松动，造成布料松紧不一，结构 复杂，维护困难。',
        }
    },
    {
        key: '7',
        projectName: {
            text: '松紧自动跟踪装置',
        },
        product: {
            text: '光电传感器实时监控布的松紧，紧了能自动调松，松 了能自动调紧。 ',
        },
        other: {
            text: '无',
            en: 'No'
        }
    },
    {
        key: '8',
        projectName: {
            text: '面料记忆功能',
        },
        product: {
            text: '30种面料的数据，可将铺过的面料数据保存，用户调 用以往的面料名称就可以，无需再设定，方便实用。',
        },
        other: {
            text: '不能记忆，无此功能',
        }
    },
    {
        key: '9',
        projectName: {
            text: '铺布效果',
        },
        product: {
            text: '平坦，对齐度好，松紧一置，松紧度好，适用面料的 种类多。 ',
        },
        other: {
            text: '偶有不平坦处，适用面料不多。不能适用高弹性， 轻薄，卷边面料。',
        }
    },
];

export default data;

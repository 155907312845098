import React, { useEffect, useState, useMemo } from 'react';
import { Table } from 'antd';
import '../../home/index.css';
import './table.css'
import { useTranslation } from 'react-i18next';
import data_en from './data_en';
import data_cn from './data_cn';

function IndustryAdvantageTable() {
    const { t, i18n } = useTranslation();
    const [dataSource, setDataSource] = useState([]);

    // useMemo to only re-calculate columns when language changes
    const columns = useMemo(() => [
        {
            title: () => <div style={{ textAlign: "center" }}>{t('advantage_table_1')}</div>,
            className: 'project-name-bg',
            dataIndex: 'projectName',
            width:180,
            key: 'projectName',
            render: text => <div>{text.text}</div>
        },
        {
            title: () => <div style={{ textAlign: "center" }}>{t('advantage_table_2')}</div>,
            className: 'product-bg',
            dataIndex: 'product',
            key: 'product',
            render: text => <div>{text.text}</div>
        },
        {
            title: () => <div style={{ textAlign: "center" }}>{t('advantage_table_3')}</div>,
            className: 'other-bg',
            dataIndex: 'other',
            key: 'other',
            render: text => <div>{text.text}</div>
        }
    ], [t]); // Dependency on the translation function `t` to update columns when language changes

    useEffect(() => {
        // Setting the data source based on language
        const loadDataSource = () => {
            setDataSource(i18n.language.startsWith('zh') ? data_cn : data_en);
        };

        loadDataSource();

        // Setup and cleanup of the language change listener
        i18n.on('languageChanged', loadDataSource);
        return () => i18n.off('languageChanged', loadDataSource);
    }, [i18n, i18n.language]); // Explicitly depend on i18n.language

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            bordered
            size="middle"
            rowHoverable={false}
        />
    );
}

export default IndustryAdvantageTable;

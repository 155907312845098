import React from 'react';
import { Tooltip } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import './index.css';
import {useTranslation} from 'react-i18next';
function PartnersHeader() {
    const {t} = useTranslation();
    return (
        <div className="headerContainer">
            <div className="headerTitle">
                {t('partners')}
            </div>
            <Tooltip title={t('view_more')}>
                <RightOutlined className="headerArrow"  />
            </Tooltip>
        </div>
    );
}

export default PartnersHeader;

import React from 'react';
import {Typography, Row, Col, Card, Space, Layout, Divider} from 'antd';
import './index.css';
import CustomTable from './table'
import {useTranslation} from 'react-i18next';

const {Title, Paragraph} = Typography;
const {Content} = Layout;


export default function ZMMachine() {
    const {t} = useTranslation();

    return (
        <Layout className="capacity-container">
            <Content>
                <Row>
                    <Card bordered={false} className="text-card">
                        <Space direction="vertical" size={16}>
                            <Title level={2}>
                                <div className="title-green-text">{t('zm_product_1')}</div>
                            </Title>
                        </Space>
                    </Card>
                    <img className="productImg" src="https://xiaomai.tech/files/images/product3.png" alt="x6 machine"/>
                    <div className="custom-table">
                        <CustomTable/>
                    </div>
                </Row>
                <Row>
                    <Col span={12}>
                        <Card bordered={false} className="text-card">
                            <Space direction="vertical" size={16}>
                                <Title level={2}>
                                    <div className="title-green-text">{t('zm_product_2')}</div>
                                </Title>
                                <Divider/>
                                <Paragraph className=" text-sentence-cn">
                                    {t('zm_product_3')}
                                </Paragraph>
                            </Space>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card bordered={false} className="text-card">
                            <Space direction="vertical" size={16}>
                                <Title level={2}>
                                    <div className="title-green-text">{t('zm_product_4')}</div>
                                </Title>
                                <Divider/>
                                <Paragraph className="text-sentence-cn">
                                    {t('zm_product_5')}
                                </Paragraph>
                            </Space>
                        </Card>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}

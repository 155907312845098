import React from 'react';
import {Table} from 'antd';
import './index.css';

const columns = [
    {
        title: () => (
            <div className="header-row">
                <span>机器型号</span>
                <span className="eng-text left-space">Model</span>
            </div>
        ),
        className: 'model-column',
        dataIndex: 'model',
        width: "300px",
        key: 'model',
        render: text => (
            <div>
                <div>{text.cn}</div>
                <div className="eng-text">{text.en}</div>
            </div>
        )
    },
    {
        title: () => <div className="header-row">ZM-160</div>,
        className: 'zm-160-190-column',
        dataIndex: 'zm_160',
        key: 'zm_160',
        render: text => (
            <div>
                <div>{text.cn}</div>
                <div className="eng-text">{text.en}</div>
            </div>
        )
    },
    {
        title: () => <div className="header-row">ZM-190</div>,
        className: 'zm-160-190-column',
        dataIndex: 'zm_190',
        key: 'zm_190',
        render: text => (
            <div>
                <div>{text.cn}</div>
                <div className="eng-text">{text.en}</div>
            </div>
        )
    },
    {
        title: () => <div className="header-row">ZM-210</div>,
        className: 'zm-210-column',
        dataIndex: 'zm_210',
        key: 'zm_210',
        render: text => (
            <div>
                <div>{text.cn}</div>
                <div className="eng-text">{text.en}</div>
            </div>
        )
    },
    {
        title: () => <div className="header-row">ZM-220</div>,
        className: 'zm-220-310-column',
        dataIndex: 'zm_220',
        key: 'zm_220',
        render: text => (
            <div>
                <div>{text.cn}</div>
                <div className="eng-text">{text.en}</div>
            </div>
        )
    }
];
const data = [
    {
        key: '1',
        model: {
            cn: '铺布宽度',
            en: 'Spread width',
        },
        zm_160: {
            cn: '1600mm',
            en: '',
        },
        zm_190: {
            cn: '1900mm',
            en: '',
        },
        zm_210: {
            cn: '2100mm',
            en: '',
        },
        zm_220: {
            cn: '2200mm',
            en: '',
        }
    },
    {
        key: '2',
        model: {
            cn: '最大面料重量',
            en: 'Maximum fabric weight',
        },
        zm_160: {
            cn: '200kg',
            en: '',
        },
        zm_190: {
            cn: '200kg',
            en: '',
        },
        zm_210: {
            cn: '200kg',
            en: '',
        },
        zm_220: {
            cn: '200kg',
            en: '',
        }
    },
    {
        key: '3',
        model: {
            cn: '最大卷布直径',
            en: 'Maximum roll diameter',
        },
        zm_160: {
            cn: '800mm',
            en: '',
        },
        zm_190: {
            cn: '800mm',
            en: '',
        },
        zm_210: {
            cn: '800mm',
            en: '',
        },
        zm_220: {
            cn: '800mm',
            en: '',
        }
    },
    {
        key: '4',
        model: {
            cn: '最大驱动速度',
            en: 'Maximum driving speed',
        },
        zm_160: {
            cn: '110m/min',
            en: '',
        },
        zm_190: {
            cn: '110m/min',
            en: '',
        },
        zm_210: {
            cn: '110m/min',
            en: '',
        },
        zm_220: {
            cn: '110m/min',
            en: '',
        }
    },
    {
        key: '5',
        model: {
            cn: '最大高度',
            en: 'Maximum height',
        },
        zm_160: {
            cn: '180mm',
            en: '',
        },
        zm_190: {
            cn: '180mm',
            en: '',
        },
        zm_210: {
            cn: '180mm',
            en: '',
        },
        zm_220: {
            cn: '180mm',
            en: '',
        }
    },
    {
        key: '6',
        model: {
            cn: '功率',
            en: 'Power',
        },
        zm_160: {
            cn: '5.5KW',
            en: '',
        },
        zm_190: {
            cn: '5.5KW',
            en: '',
        },
        zm_210: {
            cn: '5.5KW',
            en: '',
        },
        zm_220: {
            cn: '5.5KW',
            en: '',
        }
    },
    {
        key: '7',
        model: {
            cn: '电源要求',
            en: 'Power requirements',
        },
        zm_160: {
            cn: '单相或三相交流',
            en: 'Single phase or three-phase',
        },
        zm_190: {
            cn: '单相或三相交流',
            en: 'Single phase or three-phase',
        },
        zm_210: {
            cn: '单相或三相交流',
            en: 'Single phase or three-phase',
        },
        zm_220: {
            cn: '单相或三相交流',
            en: 'Single phase or three-phase',
        }
    },
    {
        key: '8',
        model: {
            cn: '尺寸:长宽高',
            en: 'Size:length,width,height',
        },
        zm_160: {
            cn: '4400×2400×2300cm',
            en: '',
        },
        zm_190: {
            cn: '4400×2700×2300cm',
            en: '',
        },
        zm_210: {
            cn: '4400×2900×2300cm',
            en: '',
        },
        zm_220: {
            cn: '4400×3120×2300cm',
            en: '',
        }
    },
    {
        key: '9',
        model: {
            cn: '裁板宽度',
            en: 'Panel weight',
        },
        zm_160: {
            cn: '1830mm',
            en: '',
        },
        zm_190: {
            cn: '2130mm',
            en: '',
        },
        zm_210: {
            cn: '2330mm',
            en: '',
        },
        zm_220: {
            cn: '2450mm',
            en: '',
        }
    },
];

function CustomTable() {
    return (
        <Table
            columns={columns}
            dataSource={data}
            bordered
            size="middle"
            pagination={false}
            rowHoverable={false}
            height={700}
        />
    );
}

export default CustomTable;

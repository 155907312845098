import React from 'react';
import { Row, Col, Typography } from 'antd';
import { PhoneOutlined } from '@ant-design/icons';
import './index.css';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

function InfoBanner() {
    const { t } = useTranslation();
    return (
        <div className="info-banner">
            <Row align="middle" justify="space-between">
                <Col>
                    <img src="https://xiaomai.tech/files/images/web_logo.png" alt={t('company_logo')} className="logo-img" />
                </Col>
                <Col flex="auto">
                    <Text className="company-info-small">{t('company_name_en')}</Text>
                    <p className="company-intro">{t('company_intro')}</p>
                </Col>
                <Col>
                    <div className="contact-info-right">
                        <PhoneOutlined className="phone-icon" />
                        <div>
                            <Text strong className="contact-text">{t('customer_service')}</Text>
                            <Title level={4} className="contact-title">{t('service_hotline')}</Title>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default InfoBanner;

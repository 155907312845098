import React from 'react';
import { Tooltip } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import './index.css';
import { useTranslation } from 'react-i18next';

function ProductHeader() {
    const { t } = useTranslation();

    return (
        <div className="headerContainer">
            <div className="headerTitle">
                {t('segment_product')}
            </div>
            <Tooltip title={t('view_more')}>
                <RightOutlined className="headerArrow" />
            </Tooltip>
        </div>
    );
}

export default ProductHeader;

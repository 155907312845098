// data.js
const advantages = [
    {
        id: 1,
        title: "1. 动力系统",
        description: "伺服电机控制，低速时也能输出大力距，定位精度高，可达微米级。",
        image: "https://xiaomai.tech/files/images/advantageimg_1.jpg"
    },
    {
        id: 2,
        title: "2. 布缓存装置",
        description: "深V槽，布缓存量大，张力充分释放，松紧适度。",
        image: "https://xiaomai.tech/files/images/advantageimg_2.jpg"
    },
    {
        id: 3,
        title: "3. 解布装置",
        description: "微电脑自动计算，光电传感自动解布送布。",
        image: "https://xiaomai.tech/files/images/advantageimg_3.jpg"
    },
    {
        id: 4,
        title: "4. 动力展平装置",
        description: "专利产品，将面料展平并可以将往里卷的布边翻出，有利于对边，对于针织卷边面料效果显著，广泛应用于针织面料。",
        image: "https://xiaomai.tech/files/images/advantageimg_4.jpg"
    },
    {
        id: 5,
        title: "5. 对边装置",
        description: "光电感应PLC自动对边控制，线路简单，灵敏度可调。继电器控制，线路复杂，维护困难。",
        image: "https://xiaomai.tech/files/images/advantageimg_5.jpg"
    },
    {
        id: 6,
        title: "6. 送布装置",
        description: "伺服电机控制，实时跟随系统，电子同步，送布张力均匀。",
        image: "https://xiaomai.tech/files/images/advantageimg_6.jpg"
    },
    {
        id: 7,
        title: "7. 松紧自动跟踪装置",
        description: "光电传感器实时监控布的松紧，紧了能自动调松，松了能自动调紧。",
        image: "https://xiaomai.tech/files/images/advantageimg_7.jpg"
    },
    {
        id: 8,
        title: "8. 面料记忆功能",
        description: "30种面料的数据，可将铺过的面料数据保存，用户调用以往的面料名称就可以，无需再设定，方便实用。",
        image: "https://xiaomai.tech/files/images/advantageimg_8.jpg"
    },
    {
        id: 9,
        title: "9. 铺布效果",
        description: "平坦，对齐度好，松紧一置，松紧度好，适用面料的种类多。",
        image: "https://xiaomai.tech/files/images/advantageimg_9.jpg"
    },
];

export default advantages;

import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import { Card, Carousel } from 'antd';
import './index.css';
import videoList from './data';
import videoListEn from './data_en';
import { useTranslation } from 'react-i18next';
const { Meta } = Card;

const SpreadersVideo = () => {
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [currentVideoId, setCurrentVideoId] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [videos, setVideos] = useState([]);
    const playerRef = useRef(null);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setVideos(i18n.language.startsWith('en') ? videoListEn : videoList);
        i18n.on('languageChanged', (lng) => {
            setVideos(lng.startsWith('en') ? videoListEn : videoList);
        });

        return () => {
            i18n.off('languageChanged');
        };
    }, [i18n]);

    const handleVideoClick = (url, id) => {
        setSelectedVideo(url);
        setPlaying(true);
        setCurrentVideoId(id);
    };

    const handleFullScreen = () => {
        if (screenfull.isEnabled) {
            screenfull.request(playerRef.current.wrapper);
        }
    };

    const handleExitFullScreen = () => {
        if (screenfull.isEnabled && screenfull.isFullscreen) {
            screenfull.exit();
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' && screenfull.isFullscreen) {
                screenfull.exit();
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className="video-gallery">
            <div className="video-player">
                {selectedVideo ? (
                    <div className="player-wrapper" ref={playerRef}>
                        <ReactPlayer
                            url={selectedVideo}
                            controls
                            playing={playing}
                            width="100%"
                            height="100%"
                            className="react-player"
                        />
                        <button className="fullscreen-button" onClick={handleFullScreen}>{t('fullScreen')}</button>
                        {screenfull.isFullscreen && (
                            <button className="exit-fullscreen-button" onClick={handleExitFullScreen}>{t('exitFullScreen')}</button>
                        )}
                    </div>
                ) : (
                    <div className="placeholder">{t('playVideo')}</div>
                )}
            </div>

            <div className="video-list-container">
                <Carousel arrows={true} dots={false} slidesToShow={5} slidesToScroll={1} autoplay autoplaySpeed={2000}>
                    {videos.map((video) => (
                        <Card
                            key={video.id}
                            className={`video-card ${currentVideoId === video.id ? 'selected' : ''}`}
                            hoverable
                            infinite={false}
                            draggable
                            cover={
                                <img
                                    className='video-card-img'
                                    alt={video.title}
                                    src={video.thumbnail}
                                />
                            }
                            onClick={() => handleVideoClick(video.url, video.id)}
                        >
                            <Meta
                                className='video-card-meta'
                                title={video.title}
                                description={video.description}
                            />
                            <div className="description-en">{video.description}</div>
                        </Card>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export default SpreadersVideo;

import React from 'react';
import { Card, Col, Row } from 'antd';

function Products() {
  const products = [
    { name: 'X5 铺布机', description: '高效率铺布机，适用于各种面料。', imageUrl: 'https://via.placeholder.com/100' },
    { name: 'X6 铺布机', description: '智能控制，精确布料处理。', imageUrl: 'https://via.placeholder.com/100' },
    { name: '子母机', description: '创新设计，满足高强度工业需求。', imageUrl: 'https://via.placeholder.com/100' }
  ];

  return (
    <Row gutter={16}>
      {products.map((product, index) => (
        <Col key={index} span={8}>
          <Card
            hoverable
            cover={<img alt={product.name} src={product.imageUrl} />}
          >
            <Card.Meta title={product.name} description={product.description} />
          </Card>
        </Col>
      ))}
    </Row>
  );
}

export default Products;

// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './locales/en/translation.json';
import translationZH from './locales/zh/translation.json';

const resources = {
    en: {
        translation: translationEN
    },
    zh: {
        translation: translationZH
    }
};

i18n
    .use(Backend) // 加载翻译文件
    .use(LanguageDetector) // 自动检测浏览器语言
    .use(initReactI18next) // 通过react-i18next绑定
    .init({
        resources,
        lng: "en",
        fallbackLng: "en",
        debug: true,
        interpolation: {
            escapeValue: false, // 不需要HTML转义
        }
    });

export default i18n;

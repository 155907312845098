import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from "antd";
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import './i18n';
import { useTranslation } from 'react-i18next';
import zhCN from 'antd/lib/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';

import './index.css';

const theme = {
    components: {
        Menu: {
            /* 这里是你的组件 token */
            darkItemBg: '#ffb700',
            darkItemSelectedBg: '#231916',
            darkItemColor: '#333',
            darkItemHoverColor: '#000',
            darkPopupBg: '#ffb700',
        },
    },
};

function WrappedApp() {
    const { i18n } = useTranslation();
    const locale = i18n.language.startsWith('zh') ? zhCN : enUS;

    return (
        <ConfigProvider theme={theme} locale={locale}>
            <Router>
                <App />
            </Router>
        </ConfigProvider>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<WrappedApp />);

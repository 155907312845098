const links = [
    {
        "title": "Key Points for Choosing a Spreader",
        "id": "key-points",
        "children": [
            {
                "title": "1. Spreader must adapt to a wide range of fabrics",
                "id": "fabric-range"
            },
            {
                "title": "2. Spreading Quality",
                "id": "spreading-quality"
            },
            {
                "title": "3. Spreading Speed",
                "id": "spreading-speed"
            },
            {
                "title": "4. Ease of Use of the Operating System",
                "id": "ease-of-use"
            },
            {
                "title": "5. Machine Stability",
                "id": "machine-stability"
            },
            {
                "title": "6. System Intelligence",
                "id": "system-intelligence"
            },
            {
                "title": "7. Energy Efficiency",
                "id": "energy-efficiency"
            }
        ]
    },
    {
        "title": "Top 5 Advantages of Xiaomai Spreaders",
        "id": "advantages",
        "children": [
            {
                "title": "1. Leading Fabric Adaptability",
                "id": "fabric-adaptability"
            },
            {
                "title": "2. Ensures Satisfactory Spreading Results",
                "id": "industry-leading"
            },
            {
                "title": "3. Fast and Stable Spreading Speed",
                "id": "high-speed"
            },
            {
                "title": "4. Intelligent Operating System",
                "id": "intelligent-system"
            },
            {
                "title": "5. Guaranteed Hardware and Workmanship",
                "id": "hardware-quality"
            }
        ]
    }
]

export default links;

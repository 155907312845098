// data.js
const advantages = [
    {
        id: 1,
        title: "1. Power system",
        description: "Servo motor control, can also output force distance at low speed,high positioning accuracy, up to micron level",
        image: "https://xiaomai.tech/files/images/advantageimg_1.jpg"
    },
    {
        id: 2,
        title: "2. Cloth caching device",
        description: "Deep V slot, cloth large cache, full release of tension,moderate elasticity.",
        image: "https://xiaomai.tech/files/images/advantageimg_2.jpg"
    },
    {
        id: 3,
        title: "3. Discharge device",
        description: "The microcomputer automatically calculates hat the photoelectric sensor automatically delivers the cloth.",
        image: "https://xiaomai.tech/files/images/advantageimg_3.jpg"
    },
    {
        id: 4,
        title: "4. Power ﬂattening device",
        description: "The patented product ﬂattens the fabric and allows it to be pulled out of the rolled fabric edge, which is beneﬁcial to the opposite side,The effect of the knitted fabric is obvious, and it is widely used in knitted fabrics.",
        image: "https://xiaomai.tech/files/images/advantageimg_4.jpg"
    },
    {
        id: 5,
        title: "5. Side-to-side device",
        description: "Photoelectric induction PLC automatic edge control,the circuit is simple, the sensitivity can be adjusted.",
        image: "https://xiaomai.tech/files/images/advantageimg_5.jpg"
    },
    {
        id: 6,
        title: "6. Feeding device",
        description: "Servo motor control,real-time follow-up system,electronic synchronization,uniform feed tension.",
        image: "https://xiaomai.tech/files/images/advantageimg_6.jpg"
    },
    {
        id: 7,
        title: "7. Elastic automatic tracking device",
        description: "Photoelectric sensors monitor the elasticity of the cloth in real time,and can automatically adjust the looseness when tightened,and automatically adjust the tension when it is loose.",
        image: "https://xiaomai.tech/files/images/advantageimg_7.jpg"
    },
    {
        id: 8,
        title: "8. Fabric memory",
        description: "The data of 30 kinds of fabrics can save the data  of the paved fabrics.The user can recall the fabric names of the past and no need to set them again.it is convenient and practical.",
        image: "https://xiaomai.tech/files/images/advantageimg_8.jpg"
    },
    {
        id: 9,
        title: "9. Spreading effect",
        description: "Flat ,good alignment,elasticity,good tightness, and a wide variety of fabrics.",
        image: "https://xiaomai.tech/files/images/advantageimg_9.jpg"
    },
];

export default advantages;

import React from 'react';
import {Typography, Row, Card, Space, Divider} from 'antd';
import './index.css';
import AdvantageTable from './table/table'
import {useTranslation} from 'react-i18next';

const {Title, Paragraph} = Typography;

export default function Advantage() {
    const {t} = useTranslation();
    return (
        <div className="advantage-container">
            <Row>
                <Card bordered={false} className="text-card">
                    <Space direction="vertical" size={16}>
                        <Title level={2}>
                            <div className="title-green-text">{t('advantage_1')}</div>
                        </Title>
                        <Divider/>
                        <Paragraph className="text-sentence-cn">
                            {t('advantage_2')}
                        </Paragraph>
                    </Space>
                </Card>
            </Row>
            <Row className="advantage-table">
                <AdvantageTable/>
            </Row>
        </div>

    );
}

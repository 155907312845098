import React from 'react';
import {Layout, Typography, Row, Col, Card, Space, Divider} from 'antd';
import './index.css';
import {useTranslation} from 'react-i18next';
const {Content} = Layout;
const {Title, Paragraph} = Typography;

export default function Capacity() {
    const {t} = useTranslation();

    return (
        <Layout className="capacity-container">
            <Content>
                <Row justify="space-between" className="display-text" gutter={16}>
                    <Col span={10} className="left-column">
                        <Card bordered={false} className="text-card">
                            <Space direction="vertical" size={16}>
                                <Title level={2}>
                                    <div className="title-green-text">{t('capacity_1')}</div>
                                </Title>
                                <Divider/>
                                <Paragraph className="text-sentence-cn">
                                    {t('capacity_2')}
                                </Paragraph>
                            </Space>

                        </Card>
                    </Col>

                    <Col span={14}>
                        <img className="full-img" src="https://xiaomai.tech/files/images/capacity_1.jpg"
                             alt="capacity1"/>
                    </Col>
                </Row>
                <Row justify="space-between" className="display-img" gutter={16}>
                    <Col span={18}>
                        <img className="full-img" src="https://xiaomai.tech/files/images/capacity_2.jpg"
                             alt="large factory"/>
                    </Col>
                    <Col span={6}>
                        <div className="image-stack">
                            <img className="stacked-img" src="https://xiaomai.tech/files/images/capacity_3.jpg"
                                 alt="image1"/>
                            <img className="stacked-img" src="https://xiaomai.tech/files/images/capacity_4.jpg"
                                 alt="image2"/>
                            <img className="stacked-img" src="https://xiaomai.tech/files/images/capacity_5.jpg"
                                 alt="image3"/>
                        </div>
                    </Col>
                </Row>

            </Content>
        </Layout>
    );
}

import React from 'react';
import { Anchor } from 'antd';
import './index.css';
import { useTranslation } from 'react-i18next';
import links from './data';
import links_en from './data_en';
const { Link } = Anchor;

function scrollToAnchor(id) {
    const anchorElement = document.getElementById(id);
    if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
}

export default function SidebarOutline() {
    const { i18n } = useTranslation();
    const currentLinks = i18n.language.startsWith('en') ? links_en : links;

    return (
        <div className="sidebar-outline">
            <Anchor affix={false} onClick={event => {
                event.preventDefault();
                // Using currentTarget to ensure the event is always linked to the bound Anchor, not any internal child element.
                const href = event.currentTarget.getAttribute('href');
                if (href) {
                    scrollToAnchor(href.substring(1));
                }
            }}>
                {currentLinks.map(section => (
                    <Link className="anchor-link-title" key={section.id} href={`#${section.id}`} title={section.title}>
                        {section.children && section.children.map(child => (
                            <Link className="anchor-link-title" key={child.id} href={`#${child.id}`} title={child.title} />
                        ))}
                    </Link>
                ))}
            </Anchor>
        </div>
    );
}

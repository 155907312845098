import React, { useState, useEffect } from 'react';
import { Avatar, Card, Col, List, Row, Tooltip, Typography, Pagination } from 'antd';
import './index.css';
import { RightOutlined } from '@ant-design/icons';
import newsItems from './data';
import newsItems_en from './data_en';
import { useTranslation } from 'react-i18next';

const { Paragraph } = Typography;

function InsideInfo() {
    const pageSize = 5;
    const [currentPage, setCurrentPage] = useState(1);
    const [currentData, setCurrentData] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        // Update the data source based on the current language
        setCurrentData(i18n.language.startsWith('en') ? newsItems_en : newsItems);
    }, [i18n.language]);

    const currentNewsItems = currentData.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    return (
        <div className="home-container">
            <Row gutter={24}>
                <Col span={14}>
                    <div className="headerContainer">
                        <div className="headerTitle">
                            {t('new_center')}
                        </div>
                        <Tooltip title={t('view_more')}>
                            <RightOutlined className="headerArrow"/>
                        </Tooltip>
                    </div>
                    <Card className="child-content">
                        <List
                            itemLayout="horizontal"
                            dataSource={currentNewsItems}
                            renderItem={item => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src={item.image}/>}
                                        title={<a href={item.link}>{item.title}</a>}
                                        description={item.content}
                                    />
                                </List.Item>
                            )}
                        />
                        <Pagination
                            current={currentPage}
                            onChange={page => setCurrentPage(page)}
                            total={currentData.length}
                            pageSize={pageSize}
                        />
                    </Card>
                </Col>
                <Col span={10}>
                    <div className="headerContainer">
                        <div className="headerTitle">
                            {t('contact_us_uppercase')}
                        </div>
                        <Tooltip title={t('view_more')}>
                            <RightOutlined className="headerArrow" />
                        </Tooltip>
                    </div>
                    <Card className="child-content">
                        <img src="https://xiaomai.tech/files/images/companybg.jpg" alt="company lookout" className="company-build"/>
                        <Paragraph><strong>{t('jiangxi_factory_address')}：</strong>
                            {t('jiangxi_factory_address_detail')}
                        </Paragraph>
                        <Paragraph><strong>{t('dongguan_factory_address')}：</strong>
                            {t('dongguan_factory_address_detail')}
                        </Paragraph>
                        <Paragraph><strong>{t('phone')}：</strong>400-050-1183</Paragraph>
                        <Paragraph><strong>{t('email')}：</strong>gnaturepowdef@gmail.com</Paragraph>
                        <Paragraph><strong>{t('companyAddress')}：</strong>xiaomai.tech</Paragraph>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default InsideInfo;

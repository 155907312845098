import React from 'react';
import { Tooltip } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import './index.css';
import { useTranslation } from 'react-i18next';

function ModuleHeader() {
    const { t } = useTranslation();
    return (
        <div className="headerContainer">
            <div className="headerTitle">
                {t('equipment_module')}
            </div>
            <Tooltip title={t('view_more')}>
                <RightOutlined className="headerArrow"  />
            </Tooltip>
        </div>
    );
}

export default ModuleHeader;

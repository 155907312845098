import React from 'react';
import {Typography, Row, Card, Space, Layout, Divider} from 'antd';
import './index.css';
import CustomTable from './table'
import {useTranslation} from 'react-i18next';

const {Title, Paragraph} = Typography;
const {Content} = Layout;


export default function X6Machine() {
    const {t} = useTranslation();

    return (
        <Layout className="capacity-container">
            <Content>
                <Row>
                    <Card bordered={false} className="text-card">
                        <Space direction="vertical" size={16}>
                            <Title level={2}>
                                <div className="title-green-text">{t('x6_product_1')}</div>
                            </Title>
                            <Divider/>
                            <Paragraph className="text-sentence-cn">
                                {t('x6_product_2')}
                            </Paragraph>
                        </Space>
                    </Card>
                    <img className="productImg" src="https://xiaomai.tech/files/images/product2.png" alt="x6 machine"/>
                    <div className="custom-table">
                        <CustomTable/>
                    </div>
                </Row>
                <Row>
                    <Card bordered={false} className="text-card">
                        <Space direction="vertical" size={16}>
                            <Title level={2}>
                                <div className="title-green-text">{t('x6_product_3')}</div>
                            </Title>
                            <Divider/>
                            <Paragraph className="text-sentence-cn">
                                {t('x6_product_4')}
                            </Paragraph>
                        </Space>
                    </Card>
                    <img className="moduleImg" src="https://xiaomai.tech/files/images/module1.png" alt="module"/>
                </Row>
            </Content>
        </Layout>
    );
}

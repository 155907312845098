const products = [
    {
        "name": "X5 Spreading Machine",
        "description": "The Xiaomai X5 series spreading machines utilize advanced technology, offering various models to meet different requirements. With spreading widths ranging from 1600mm to 3100mm and a maximum fabric weight of 110kg, they ensure efficient and stable performance. These machines are widely used in industrial applications and are an ideal choice in the textile sector.",
        "imageUrl": "https://xiaomai.tech/files/images/product1.png",
        route:'x5'
    },
    {
        "name": "X6 Spreading Machine",
        "description": "The Xiaomai X6 series spreading machines are highly favored in the textile industry for their efficient and stable performance. They offer spreading widths from 1600mm to 3100mm and a maximum fabric weight of 110kg, maintaining a maximum driving speed of 85-88m/min to meet high production demands.",
        "imageUrl": "https://xiaomai.tech/files/images/product2.png",
        route:'x6'
    },
    {
        "name": "Mother-Child Machine",
        "description": "Designed for handling more complex fabric types, the Mother-Child machine can process multiple fabrics simultaneously, unlike the X5 and X6 series. It is suitable for multi-layered and specialty materials, providing significant advantages when dealing with complex and varied fabric demands.",
        "imageUrl": "https://xiaomai.tech/files/images/product3.png",
        route:'zm'
    }
]


export default products;

const links = [
    {
        "title": "选购铺布机要点",
        "id": "key-points",
        "children": [
            {
                "title": "1.铺布机适应面料要广",
                "id": "fabric-range"
            },
            {
                "title": "2.铺布效果",
                "id": "spreading-quality"
            },
            {
                "title": "3.铺布速度",
                "id": "spreading-speed"
            },
            {
                "title": "4.操作系统的易用性",
                "id": "ease-of-use"
            },
            {
                "title": "5.机器的稳定性",
                "id": "machine-stability"
            },
            {
                "title": "6.系统智能化",
                "id": "system-intelligence"
            },
            {
                "title": "7.节能省电",
                "id": "energy-efficiency"
            }
        ]
    },
    {
        "title": "小麦铺布的5大优势",
        "id": "advantages",
        "children": [
            {
                "title": "1.领先的面料适应能力",
                "id": "fabric-adaptability"
            },
            {
                "title": "2.保证令人满意的铺布效果",
                "id": "industry-leading"
            },
            {
                "title": "3.铺布速度又快又稳",
                "id": "high-speed"
            },
            {
                "title": "4.智能化操作系统",
                "id": "intelligent-system"
            },
            {
                "title": "5.硬件及做工有保障",
                "id": "hardware-quality"
            }
        ]
    }
]

export default links;

const products = [
    {
        name: 'X5 铺布机',
        description: '小麦X5系列铺布机采用先进技术，拥有多种型号满足不同需求，铺布宽度从1600mm至3100mm不等，最大面料重量可达110kg，确保高效稳定的操作表现。该系列设备广泛适用于工业应用，是纺织领域的理想选择。',
        imageUrl: 'https://xiaomai.tech/files/images/product1.png',
        route:'x5'
    },
    {
        name: 'X6 铺布机',
        description: '小麦X6系列铺布机以其高效稳定的性能在纺织行业中备受青睐。各型号铺布宽度从1600mm至3100mm，最大面料重量可达110kg，最大驱动速度保持在85-88m/min之间，确保了高效率的生产需求。',
        imageUrl: 'https://xiaomai.tech/files/images/product2.png',
        route:'x6'
    },
    {
        name: '子母机',
        description: '子母机设计用于更复杂的布料处理，与X5和X6系列铺布机相比，子母机可以同时处理多种布料，适合于多层布料和特种材料的铺设。这使得子母机在应对复杂和多变布料需求时具有明显的优势。',
        imageUrl: 'https://xiaomai.tech/files/images/product3.png',
        route:'zm'
    }
];

export default products;

const videoList = [
    {
        id: 23,
        title: 'Support single player operation',
        description: 'One person operates the machine, with a maximum production capacity of four workers, making it particularly suitable for fabrics with high elasticity!',
        url: 'https://xiaomai.tech/files/videos/knit_fabric.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/knit_fabric.png'
    },
    {
        id: 22,
        title: 'Soft fabric automatic cutting machine',
        description: 'Suitable for various alien pieces, one positioning, cutting while walking.',
        url: 'https://xiaomai.tech/files/videos/nzml01.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/nzml01.png'
    },
    {
        id: 1,
        title: 'Elastic Fabrics',
        description: 'Elastic, knit fabric operation video.',
        url: 'https://xiaomai.tech/files/videos/knit_1.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/knit_1.png'
    }, {
        id: 2,
        title: 'Denim',
        description: 'Denim fabric operation video.',
        url: 'https://xiaomai.tech/files/videos/cowboy_1.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/cowboy_1.png'
    }, {
        id: 3,
        title: 'Leather',
        description: 'Leather fabric operation video.',
        url: 'https://xiaomai.tech/files/videos/leather_1.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/leather_1.png'
    }, {
        id: 4,
        title: 'X6-210',
        description: 'Real-time operation video of the X6-210 spreader.',
        url: 'https://xiaomai.tech/files/videos/x6_210.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/x6_210.png'
    }, {
        id: 5,
        title: 'Industries for X6',
        description: 'X6 spreader suitable for multiple industries, a must-have.',
        url: 'https://xiaomai.tech/files/videos/x6_intro_1.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/x6_intro_1.png'
    }, {
        id: 6,
        title: 'Uses of X6',
        description: 'Multipurpose X6 spreader, a must-have.',
        url: 'https://xiaomai.tech/files/videos/x6_intro_2.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/x6_intro_2.png'
    }, {
        id: 7,
        title: 'Functions of Xiaomai Spreader',
        description: 'The extraordinary Xiaomai spreader, a must-have.',
        url: 'https://xiaomai.tech/files/videos/x6_intro_3.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/x6_intro_3.png'
    }, {
        id: 8,
        title: 'Features of X6 Spreader',
        description: 'X6 spreader, the fabric-friendly machine.',
        url: 'https://xiaomai.tech/files/videos/x6_intro_4.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/x6_intro_4.png'
    }, {
        id: 9,
        title: 'Company Introduction',
        description: 'Introduction to Dongguan Xiaomai Automation Technology Co., Ltd.',
        url: 'https://xiaomai.tech/files/videos/company_intro_1.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/company_intro_1.png'
    }, {
        id: 10,
        title: 'Factory Construction',
        description: 'Introduction to factory construction.',
        url: 'https://xiaomai.tech/files/videos/company_intro_2.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/company_intro_2.png'
    }, {
        id: 11,
        title: 'Other Models',
        description: 'Spreader videos.',
        url: 'https://xiaomai.tech/files/videos/spreaders_1.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/spreaders_1.png'
    }, {
        id: 12,
        title: 'Hat Industry',
        description: 'Manufacturing in the hat industry.',
        url: 'https://xiaomai.tech/files/videos/maozi.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/maozi.png'
    }, {
        id: 13,
        title: 'Woven Fabrics',
        description: 'Woven fabric manufacturing.',
        url: 'https://xiaomai.tech/files/videos/suozhimianliao.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/suozhimianliao.png'
    }, {
        id: 14,
        title: 'Elastic Fabrics Operation',
        description: 'Actual operation video of elastic fabrics.',
        url: 'https://xiaomai.tech/files/videos/tanxing.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/tanxing.png'
    }, {
        id: 15,
        title: 'Mesh Fabrics',
        description: 'Mesh fabric manufacturing.',
        url: 'https://xiaomai.tech/files/videos/wangshamianliao.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/wangshamianliao.png'
    }, {
        id: 16,
        title: 'Knit Fabrics',
        description: 'Knit fabric manufacturing.',
        url: 'https://xiaomai.tech/files/videos/zhenzhimianliao.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/zhenzhimianliao.png'
    }, {
        id: 17,
        title: 'Perfect Solution for Double Curling',
        description: 'Perfect solution for high-elasticity fabrics with curling edges!',
        url: 'https://xiaomai.tech/files/videos/video_center_17.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/video_center_17.png'
    }, {
        id: 18,
        title: 'One Person Operates One Machine',
        description: 'One person operating one machine, perfectly replacing the efficiency of four workers.',
        url: 'https://xiaomai.tech/files/videos/video_center_18.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/video_center_18.png'
    }, {
        id: 19,
        title: 'Smooth and Tension-Free Spreading',
        description: 'Elastic fabric spreading with smooth and tension-free effects video.',
        url: 'https://xiaomai.tech/files/videos/video_center_19.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/video_center_19.png'
    }, {
        id: 20,
        title: 'Spreading Comparison',
        description: 'Comparison of machine and manual spreading effects.',
        url: 'https://xiaomai.tech/files/videos/video_center_20.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/video_center_20.png'
    }, {
        id: 21,
        title: 'Swimwear Fabrics',
        description: 'Swimwear fabric manufacturing.',
        url: 'https://xiaomai.tech/files/videos/video_center_21.mp4',
        thumbnail: 'https://xiaomai.tech/files/thumbnail/video_center_21.png'
    },
    // Continue adding more videos
];

export default videoList;

import React from 'react';
import {Divider, Card, Col, Row} from 'antd';
import './index.css';
import SidebarOutline from './Siderbar';
import { useTranslation } from 'react-i18next';

export default function ChooseSpreadingMachine() {
    const { t } = useTranslation();

    return (
        <div className="chooseSpreader">
            <div className="header" style={{
                backgroundImage: `url('https://xiaomai.tech/files/images/big1.png')`,
                backgroundSize: 'cover',
                height: '400px'
            }}>
                {/* Header could be empty if it's only for a background image */}
            </div>
            <div className="chooseSpreader">
                <SidebarOutline/>
            </div>

            <div className="content">
                <div id="key-points" className="anchor-target"/>
                <h1 className="page-title">{t('purchaseguide_1')}</h1>
                <Divider orientation="left" className="intro-eng">{t('purchaseguide_2')}</Divider>

                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <div id="fabric-range" className="anchor-target"/>
                        <Card
                            hoverable
                            title={
                                <div>
                                    <span className="card-title-cn">{t('purchaseguide_3')}</span>
                                </div>
                            }
                        >
                            <p className="sequence-text-content">
                                {t('purchaseguide_4')}
                            </p>
                        </Card>
                    </Col>

                    <Col span={24}>
                        <div className="anchor-target" id="spreading-quality"/>
                        <Card
                            hoverable
                            title={
                                <div>

                                    <span className="card-title-cn">{t('purchaseguide_5')}</span>
                                </div>
                            }
                        >
                            <p className="sequence-text-content">
                                {t('purchaseguide_6')}
                            </p>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <div id="spreading-speed" className="anchor-target"/>
                        <Card
                            hoverable
                            title={
                                <div>
                                    <span className="card-title-cn">{t('purchaseguide_7')}</span>
                                </div>
                            }
                        >
                            <p className="sequence-text-content">
                                {t('purchaseguide_8')}
                            </p>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <div id="ease-of-use" className="anchor-target"/>
                        <Card
                            hoverable
                            title={
                                <div>
                                    <span className="card-title-cn">{t('purchaseguide_9')}</span>
                                </div>
                            }
                        >
                            <p className="sequence-text-content">
                                {t('purchaseguide_10')}
                            </p>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <div id="machine-stability" className="anchor-target"/>
                        <Card
                            hoverable
                            title={
                                <div>
                                    <span className="card-title-cn">{t('purchaseguide_11')}</span>
                                </div>
                            }
                        >
                            <p className="sequence-text-content">
                                {t('purchaseguide_12')}
                            </p>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <div id="system-intelligence" className="anchor-target"/>
                        <Card
                            hoverable
                            title={
                                <div>
                                    <span className="card-title-cn">{t('purchaseguide_13')}</span>
                                </div>
                            }
                        >
                            <p className="sequence-text-content">
                                {t('purchaseguide_14')}
                            </p>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <div id="energy-efficiency" className="anchor-target"/>
                        <Card
                            hoverable
                            title={
                                <div>
                                    <span className="card-title-cn">{t('purchaseguide_15')}</span>
                                </div>
                            }
                        >
                            <p className="sequence-text-content">
                                {t('purchaseguide_16')}
                            </p>
                        </Card>
                    </Col>
                </Row>
                <div id="advantages" className="anchor-target"/>
                <Divider orientation="left" className="intro-eng"> {t('purchaseguide_17')}</Divider>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <div id="fabric-adaptability" className="anchor-target"/>
                        <Card
                            hoverable
                            title={
                                <div>
                                    <span className="card-title-cn"> {t('purchaseguide_18')}</span>
                                </div>
                            }
                        >
                            <p className="sequence-text-content">
                                {t('purchaseguide_19')}
                            </p>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <div id="industry-leading" className="anchor-target"/>
                        <Card
                            hoverable
                            title={
                                <div>
                                    <span className="card-title-cn"> {t('purchaseguide_20')}</span>
                                </div>
                            }
                        >
                            <p className="sequence-text-content">
                                {t('purchaseguide_21')}
                            </p>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <div id="high-speed" className="anchor-target"/>
                        <Card
                            hoverable
                            title={
                                <div>
                                    <span className="card-title-cn"> {t('purchaseguide_22')}</span>
                                </div>
                            }
                        >
                            <p className="sequence-text-content">
                                {t('purchaseguide_23')}
                            </p>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <div id="intelligent-system" className="anchor-target"/>
                        <Card
                            hoverable
                            title={
                                <div>
                                    <span className="card-title-cn">{t('purchaseguide_24')}</span>
                                </div>
                            }
                        >
                            <p className="sequence-text-content">
                                {t('purchaseguide_25')}
                            </p>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <div id="hardware-quality" className="anchor-target"/>
                        <Card
                            hoverable
                            title={
                                <div>
                                    <span className="card-title-cn">{t('purchaseguide_26')}</span>
                                </div>
                            }
                        >
                            <p className="sequence-text-content">
                                {t('purchaseguide_27')}
                            </p>
                        </Card>
                    </Col>
                </Row>

            </div>
        </div>);
}

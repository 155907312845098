import React from 'react';
import {Row, Col} from 'antd';
import "./index.css"
import {useTranslation} from 'react-i18next';

function HonorPage() {
    const {t} = useTranslation();

    return (
        <div>
            <p>{t('companyHonorPage_1')} <span className="honor-text">{t('companyHonorPage_2')}</span></p>
            <p className="honor-text">{t('companyHonorPage_3')}</p>
            <Row>
                <Col span={24}>
                    <img width="100%" height="800px" src="https://xiaomai.tech/files/images/honorcetificate.png"
                         alt="honor certificate"/>
                </Col>
            </Row>
        </div>
    );
}

export default HonorPage;

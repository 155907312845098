import React from 'react';
import { Card } from 'antd';
import "./index.css";

function ProductCard({ product, cardStyle,onClick }) {
    return (
        <Card
            className="cardInfo"
            hoverable
            style={cardStyle}
            onClick={() => onClick(product.route)}
            cover={<img className='card_img' alt={product.name} src={product.imageUrl} />}
        >
            <Card.Meta title={product.name} description={product.description} />
        </Card>
    );
}

export default ProductCard;

import {useState, useEffect} from "react";
import {Carousel, Col, Row, Space, Divider, Modal} from 'antd';
import ProductHeader from './productPage/productHeader';
import InfoBanner from './infoBanner';
import ModuleHeader from './moduleHeader';
import IndustryHeader from './industryHeader';
import AdvantageHeader from './advantagePage/advantageHeader';
import AdvantageDisplay from './advantagePage/advantageDisplay';
import ProductCard from './productPage/card';
import PartnersHeader from './partnerPage/partnerHeader';
import PartnerCarousel from './partnerPage/partnerCarousel';
import InsideInfo from './insideInfo';
import './index.css';
import {useTranslation} from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// 引入数据文件
import productsDefault from './data.js';
import productsEnglish from './data_en.js';
import {gtag_report_home_page_visit} from '../../utils';


export default function Home() {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate(); // useNavigate instead of useHistory    const [cardHeight, setCardHeight] = useState(0);
    const [cardHeight, setCardHeight] = useState(0);

    const [products, setProducts] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [activeVideo] = useState(null);
    useEffect(() => {
        // 当页面加载时，调用谷歌分析首页访问跟踪
        gtag_report_home_page_visit();

        const loadProducts = () => {
            // 根据当前语言设置加载相应的数据
            const productsData = i18n.language.startsWith('en') ? productsEnglish : productsDefault;
            setProducts(productsData);
        };

        loadProducts();
        // 设置卡片高度根据当前语言
        setCardHeight(i18n.language.startsWith('zh') ? 355 : 432);

        // 监听语言变化并更新产品和卡片高度
        const handleLanguageChange = () => {
            setProducts(i18n.language.startsWith('en') ? productsEnglish : productsDefault);
            setCardHeight(i18n.language.startsWith('zh') ? 355 : 432);
        };

        // 监听语言变化
        i18n.on('languageChanged', handleLanguageChange);
        i18n.on('languageChanged', loadProducts);

        // 清理监听器
        return () => {
            i18n.off('languageChanged', loadProducts);
            i18n.on('languageChanged', handleLanguageChange);
        };
    }, [i18n]);

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleProductClick = route => {
        navigate(route); // Using navigate for navigation
    };

    return (
        <div className="home">
            <div className="carousel">
                <Carousel autoplay arrows arrowSize={30}>
                    <div>
                        <img className="slide-img" src="https://xiaomai.tech/files/images/big1.png" alt="slide1"/>
                    </div>
                    <div>
                        <img className="slide-img" src="https://xiaomai.tech/files/images/big2.png" alt="slide2"/>
                    </div>
                    <div>
                        <img className="slide-img" src="https://xiaomai.tech/files/images/big3.png" alt="slide3"/>
                    </div>
                </Carousel>
            </div>
            <div className="container">
                <ProductHeader/>
                <Row gutter={16}>
                    {products.map((product, index) => (
                        <Col key={index} span={8}>
                            <ProductCard
                                product={product}
                                cardStyle={{ height: `${cardHeight}px` }}
                                onClick={handleProductClick}
                            />
                        </Col>
                    ))}
                </Row>
                <div className="videoPlayer">
                    <Carousel arrows autoplay className="video-carousel">
                        <div>
                            <video width="100%" height="600px" controls>
                                <source src="https://xiaomai.tech/files/videos/video1.mp4" type="video/mp4"/>
                            </video>
                        </div>
                        <div>
                            <video width="100%" height="600px" controls>
                                <source src="https://xiaomai.tech/files/videos/video2.mp4" type="video/mp4"/>
                            </video>
                        </div>
                        <div>
                            <video width="100%" height="600px" controls>
                                <source src="https://xiaomai.tech/files/videos/video3.mp4" type="video/mp4"/>
                            </video>
                        </div>
                    </Carousel>
                    <Modal title="Video Player" open={isModalVisible} onCancel={handleCancel} footer={null}
                           width={700}>
                        <video width="100%" controls autoPlay>
                            <source src={activeVideo} type="video/mp4"/>
                        </video>
                    </Modal>
                </div>

                <div className="module">
                    <ModuleHeader/>
                    <div className="module-title">{t('equipment_module')}</div>
                    <div className="textAlignLeft"><img className="module_img"
                                                        src="https://xiaomai.tech/files/images/module1.png"
                                                        alt="industry1"/></div>
                </div>
                <div className="industry">
                    <IndustryHeader/>
                    <div className="industry-title">{t('whatxiaomai_industry')}</div>
                    <Space direction="vertical" size="middle">
                        <div className="textAlignLeft"><img className="industry-img"
                                                            src="https://xiaomai.tech/files/images/industry1.png"
                                                            alt="industry1"/></div>
                        <div className="textAlignRight"><img className="industry-img"
                                                             src="https://xiaomai.tech/files/images/industry2.png"
                                                             alt="industry2"/></div>
                        <div className="textAlignLeft"><img className="industry-img"
                                                            src="https://xiaomai.tech/files/images/industry3.png"
                                                            alt="industry3"/></div>
                        <div className="textAlignRight"><img className="industry-img"
                                                             src="https://xiaomai.tech/files/images/industry4.png"
                                                             alt="industry4"/></div>
                        <div className="textAlignLeft"><img className="industry-img"
                                                            src="https://xiaomai.tech/files/images/industry5.png"
                                                            alt="industry5"/></div>
                    </Space>
                </div>

                <InfoBanner/>

                <div className="advantage">
                    <AdvantageHeader/>
                    <AdvantageDisplay/>
                </div>

                <div className="home-about">
                    <Divider>{t('aboutXM')}</Divider>
                    <div className="home-about-content">
                        <img className="about_img" src="https://xiaomai.tech/files/images/company.png" alt="company"/>
                        <div className="company-content">
                            <div>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('aboutXMDesc')}
                            </div>
                        </div>
                    </div>
                    <div className="company-content">
                        <div>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('aboutXMContent')}
                        </div>
                    </div>
                </div>


                <div className="partners">
                    <PartnersHeader/>
                    <PartnerCarousel/>
                </div>

                <div className="inside-info">
                    <InsideInfo/>
                </div>

            </div>
        </div>
    );
}

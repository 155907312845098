import React from 'react';
import {Layout} from 'antd';
import './index.css';
import {useTranslation} from 'react-i18next';

const {Footer} = Layout;

function PageFooter() {
    const {t} = useTranslation();
    return (
        <Footer className="page-footer">
            <div className="page-container">
                <div>
                    <img className="footer_img" src="https://xiaomai.tech/files/images/footerLogo.png"
                         alt="footer-logo"/>
                    <div><span className="text-bolder">{t('phone')}：</span>400-050-1183</div>
                    <div><span className="text-bolder">{t('fax')}：</span>0769-83236839</div>
                    <div><span className="text-bolder">{t('email')}：</span>gnaturepowdef@gmail.com</div>
                    <div><span className="text-bolder">{t('whatsapp')}：</span>+86 189-2913-1303</div>
                </div>
                <div className="company">
                    <div className="company-name">
                        <div className="cn">{t('company_name')}</div>
                    </div>
                    <div className="company-address">
                        <div className="cn">{t('dongguan_factory_address_detail')}</div>
                    </div>
                    <div className="copyright">{t('banquan')}</div>
                </div>
                <div>
                    <div className="QRcode-title">{t('whatsapp')}</div>
                    <img className="qrcode_img" src="https://xiaomai.tech/files/images/whatsapp_cut.png"
                         alt="footer-logo"/>
                </div>
            </div>
        </Footer>
    );
}

export default PageFooter;

import {useState, useEffect} from "react";

import {Row, Col, Card, Typography} from 'antd';
import './index.css';
import {useTranslation} from 'react-i18next';

import advantages from './data';
import advantages_en from './data_en';


const {Title, Text} = Typography;

function AdvantageDisplay() {
    const {t, i18n} = useTranslation();
    const [advantagesData, setAdvantagesData] = useState([]);

    useEffect(() => {
        const loadAdvantages = () => {
            // 根据当前语言设置加载相应的数据
            const advantagesData = i18n.language.startsWith('en') ? advantages_en : advantages;
            setAdvantagesData(advantagesData);
        };

        loadAdvantages();

        i18n.on('languageChanged', loadAdvantages);

        // 清理监听器
        return () => {
            i18n.off('languageChanged', loadAdvantages);
        };
    }, [i18n]);

    return (
        <div className="features-container">
            <Row justify="space-between" align="top">
                <Col span={11} className="card-section">
                    {advantagesData.map((adv, index) => index % 2 === 0 && (
                        <Card className="feature-card" key={adv.id}>
                            <div className="card-content">
                                {(adv.id === 1 || adv.id === 5 || adv.id === 9) && (
                                    <img src={adv.image} alt={adv.title} className="feature-image"/>
                                )}
                                <div className="text-content">
                                    <Title level={4} className="feature-title">{adv.title}</Title>
                                    <Text className="feature-description">{adv.description}</Text>
                                    <Text className="feature-description-small">{adv.descriptionEn}</Text>
                                </div>
                                {(adv.id === 3 || adv.id === 7) && (
                                    <img src={adv.image} alt={adv.title} className="feature-image"/>
                                )}
                            </div>
                        </Card>
                    ))}
                </Col>
                <Col span={2} className="title-section">
                    <Title level={2} className="vertical-title">{t('9advantageOfXiaomai')}</Title>
                </Col>
                <Col span={11} className="card-section">
                    {advantagesData.map((adv, index) => index % 2 !== 0 && (
                        <Card className="feature-card" key={adv.id}>
                            <div className="card-content">
                                {(adv.id === 4 || adv.id === 8) && (
                                    <img src={adv.image} alt={adv.title} className="feature-image"/>
                                )}
                                <div className="text-content">
                                    <Title level={4} className="feature-title">{adv.title}</Title>
                                    <Text className="feature-description">{adv.description}</Text>
                                    <Text className="feature-description-small">{adv.descriptionEn}</Text>
                                </div>
                                {(adv.id === 2 || adv.id === 6) && (
                                    <img src={adv.image} alt={adv.title} className="feature-image"/>
                                )}
                            </div>
                        </Card>
                    ))}
                </Col>
            </Row>
        </div>
    );
}

export default AdvantageDisplay;

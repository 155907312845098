// data.js

const newsItems = [
    {
        title: "革命性的铺布机新技术",
        content: "OSHIMA推出的新型铺布机，可以同时替代六种传统机型，提高生产效率。",
        image: "https://xiaomai.tech/files/images/newsItem_1.jpg",
        link:"https://baike.baidu.com/item/%E5%8D%95%E9%92%88%E7%BB%97%E7%BC%9D%E6%9C%BA/4111900?fr=ge_ala"
    },
    {
        title: "自动铺布机的重要进展",
        content: "全自动铺布机能够无张力地展开各种重达200公斤的织物卷。",
        image: "https://xiaomai.tech/files/images/newsItem_2.jpg",
        link:"https://baike.baidu.com/item/%E5%8D%95%E9%92%88%E7%BB%97%E7%BC%9D%E6%9C%BA/4111900?fr=ge_ala"
    },
    {
        title: "全球首个电动铺布机的启用",
        content: "KWS公司投资使用全球首个无排放的电动铺布机，开启道路建设新篇章。",
        image: "https://xiaomai.tech/files/images/newsItem_3.jpg",
        link:"https://baike.baidu.com/item/%E5%8D%95%E9%92%88%E7%BB%97%E7%BC%9D%E6%9C%BA/4111900?fr=ge_ala"
    },
    {
        title: "铺布机行业的环保创新",
        content: "新一代铺布机配备了最新电子设备和定制软件，确保性能始终处于最佳状态。",
        image: "https://xiaomai.tech/files/images/newsItem_4.jpg",
        link:"https://baike.baidu.com/item/%E5%8D%95%E9%92%88%E7%BB%97%E7%BC%9D%E6%9C%BA/4111900?fr=ge_ala"
    },
    {
        title: "自动铺布技术的应用扩展",
        content: "自动铺布机不仅用于针织材料，现在开始广泛应用于重型和其他材质。",
        image: "https://xiaomai.tech/files/images/newsItem_5.jpg",
        link:"https://baike.baidu.com/item/%E5%8D%95%E9%92%88%E7%BB%97%E7%BC%9D%E6%9C%BA/4111900?fr=ge_ala"
    },
    {
        title: "铺布机操作的自动化和简化",
        content: "现代铺布机通过自动线程系统，大大简化了从摇篮到接收器的织物处理过程。",
        image: "https://xiaomai.tech/files/images/newsItem_6.jpg",
        link:"https://baike.baidu.com/item/%E5%8D%95%E9%92%88%E7%BB%97%E7%BC%9D%E6%9C%BA/4111900?fr=ge_ala"
    },
    {
        title: "面料记忆功能的铺布机",
        content: "最新的铺布机能够保存30种面料的数据，简化了操作流程，提高了工作效率。",
        image: "https://xiaomai.tech/files/images/newsItem_7.jpg",
        link:"https://baike.baidu.com/item/%E5%8D%95%E9%92%88%E7%BB%97%E7%BC%9D%E6%9C%BA/4111900?fr=ge_ala"
    },
    {
        title: "自动铺布机的市场反响",
        content: "由于操作简便和高效能，自动铺布机在市场上受到了广泛欢迎。",
        image: "https://xiaomai.tech/files/images/newsItem_8.jpg",
        link:"https://baike.baidu.com/item/%E5%8D%95%E9%92%88%E7%BB%97%E7%BC%9D%E6%9C%BA/4111900?fr=ge_ala"
    },
    {
        title: "新型铺布机助力纺织行业发展",
        content: "新型铺布机的设计为纺织行业带来了新的发展机遇，特别是在提高生产效率方面。",
        image: "https://xiaomai.tech/files/images/newsItem_9.jpg",
        link:"https://baike.baidu.com/item/%E5%8D%95%E9%92%88%E7%BB%97%E7%BC%9D%E6%9C%BA/4111900?fr=ge_ala"
    },
    {
        title: "下一代智能铺布解决方案",
        content: "最新一代铺布机集成了先进的智能控制系统，能够精确地处理各种类型的面料。",
        image: "https://xiaomai.tech/files/images/newsItem_10.jpg",
        link:"https://baike.baidu.com/item/%E5%8D%95%E9%92%88%E7%BB%97%E7%BC%9D%E6%9C%BA/4111900?fr=ge_ala"
    }
];

export default newsItems;
